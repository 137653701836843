import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

function GameDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const { id: rawgId } = useParams();
  const [game, setGame] = useState(location.state?.game || null);
  const [loading, setLoading] = useState(true);
  const [subscriptionAvailability, setSubscriptionAvailability] =
    useState(null);
  const [subscriptionLoading, setSubscriptionLoading] = useState(true);
  const [isExiting, setIsExiting] = useState(false);

  useEffect(() => {
    if (!game) {
      fetchGameDetails(rawgId);
    } else {
      setLoading(false);
      fetchSubscriptionData(game.id);
    }
  }, [game, rawgId]);

  const fetchGameDetails = async (rawgId) => {
    try {
      const response = await fetch(
        `https://getgamebyrawgid-rt2xgvdeua-uc.a.run.app?rawgId=${rawgId}`
      );
      const data = await response.json();
      setGame(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching game details:", error);
      setLoading(false);
    }
  };

  const fetchSubscriptionData = async (gameId) => {
    try {
      const response = await fetch(
        `https://getsubscriptions-rt2xgvdeua-uc.a.run.app?gameId=${gameId}`
      );
      const data = await response.json();
      setSubscriptionAvailability(data);
    } catch (error) {
      console.error("Error fetching subscription data:", error);
    } finally {
      setSubscriptionLoading(false);
    }
  };

  const handleBackClick = () => {
    setIsExiting(true);
    setTimeout(() => {
      navigate(-1);
    }, 250);
  };

  if (!game) {
    return (
      <div className="text-center text-gray-300">No game data available.</div>
    );
  }

  if (loading) {
    return (
      <div className="text-center text-gray-300">Loading game details...</div>
    );
  }

  return (
    <div
      className="flex flex-col items-center justify-center min-h-screen bg-cover bg-center text-gray-300 p-4"
      style={{ backgroundImage: "url('./images/background.jpg')" }}
    >
      <div className="absolute top-4 right-4">
        <a
          href="https://ko-fi.com/whereplay"
          className="px-4 py-2 bg-purple-400 text-white rounded hover:bg-purple-500 transition-colors"
        >
          💸 Support WherePlay
        </a>
      </div>
      <div
        className={`absolute top-8 left-1/2 transform -translate-x-1/2 flex items-center text-6xl font-bold text-gray-300 ${
          isExiting ? "fade-out" : ""
        }`}
      >
        <span className="mr-4">🎮</span>
        <h1>
          Where
          <span className="text-purple-400">Play</span>
        </h1>
      </div>
      <div
        className={`relative w-full max-w-4xl bg-gray-900 bg-opacity-80 p-6 rounded-lg ${
          isExiting ? "fade-out" : "fade-in"
        }`}
        style={{
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.6)",
        }}
      >
        <button
          onClick={handleBackClick}
          className="absolute top-4 left-4 px-4 py-2 bg-purple-500 text-white rounded hover:bg-purple-600"
        >
          Back
        </button>
        <h1
          className="text-4xl font-bold mb-4 text-center"
          style={{
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.8)",
          }}
        >
          {game.name}
        </h1>
        <img
          src={game.background_image}
          alt={game.name}
          className="w-96 h-54 object-cover rounded mb-4 mx-auto"
        />

        {subscriptionLoading ? (
          <div className="text-center text-gray-300">
            Loading subscription data...
          </div>
        ) : subscriptionAvailability ? (
          <div className="grid grid-cols-2 gap-4 text-center">
            {Object.entries(subscriptionAvailability)
              .filter(([key]) => key !== "name" && key !== "rawgId")
              .sort(([a], [b]) => a.localeCompare(b))
              .map(([service, isAvailable]) => (
                <div key={service} className="flex flex-col items-center">
                  <span className="text-lg font-semibold">
                    {formatToPascalCase(service)}
                  </span>
                  <span
                    className={`text-2xl transform transition-transform duration-300 ${
                      isAvailable
                        ? "text-green-500 scale-125"
                        : "text-red-500 scale-125"
                    } hover:scale-150`}
                  >
                    {isAvailable ? "✓" : "✗"}
                  </span>
                </div>
              ))}
          </div>
        ) : (
          <div className="text-center text-gray-300 text-2xl">
            Oh no! There's no subscription data available. <br />
            This usually means that the game isn't available on any services.
          </div>
        )}

        <div className="text-center mt-4">
          <a
            href={`mailto:contact@whereplay.net?subject=${game.name}`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-purple-400 hover:underline"
          >
            Did we get something wrong? Let us know
          </a>
        </div>
      </div>
      <footer className="absolute bottom-4 text-gray-400 text-sm">
        Powered by{" "}
        <a
          href="https://rawg.io/"
          target="_blank"
          rel="noopener noreferrer"
          className="text-purple-400 hover:underline"
        >
          RAWG
        </a>
      </footer>
    </div>
  );
}

const formatToPascalCase = (str) => {
  if (str === "eaPlay") {
    return "EA Play";
  }

  return str
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/_/g, " ")
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export default GameDetails;
