import React, { useState, useEffect, useCallback } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import "./HomeSearch.css";
import { setLogLevel } from "firebase/app";
import GameDetails from "./GameDetails";

setLogLevel("debug");

function HomeSearch() {
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [games, setGames] = useState([]);
  const [isExiting, setIsExiting] = useState(false);
  const navigate = useNavigate();

  const fetchGames = useCallback(
    async (query) => {
      try {
        const response = await fetch(
          `https://searchgames-rt2xgvdeua-uc.a.run.app?query=${query}`
        );
        const data = await response.json();
        setGames(data.results || []);
      } catch (error) {
        console.error("Error fetching games:", error);
      }
    },
    []
  );

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 250);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      fetchGames(debouncedSearchTerm);
    } else {
      setGames([]);
    }
  }, [debouncedSearchTerm, fetchGames]);

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleGameClick = (game) => {
    setIsExiting(true);
    setTimeout(() => {
      navigate(`/game/${game.id}`, { state: { game } });
    }, 250);
  };

  return (
    <div
      className="flex flex-col min-h-screen bg-cover bg-center"
      style={{ backgroundImage: "url('../images/background.jpg')" }}
    >
      <div className="absolute top-4 right-4">
        <a
          href="https://ko-fi.com/whereplay"
          className="px-4 py-2 bg-purple-400 text-white rounded hover:bg-purple-500 transition-colors"
        >
          💸 Support WherePlay
        </a>
      </div>
      <div className="flex flex-col items-center justify-center flex-grow mb-24">
        <div className={`text-8xl mb-4 fade-in ${isExiting ? "ease-out" : ""}`}>
          🎮
        </div>
        <h1
          className={`text-6xl font-bold text-gray-300 mb-8 fade-in ${
            isExiting ? "ease-out" : ""
          }`}
        >
          Where
          <span className="text-purple-400">Play</span>
        </h1>
        <div
          className={`relative w-96 fade-in-delayed ${
            isExiting ? "ease-out" : ""
          }`}
        >
          <input
            type="text"
            placeholder="Search for games..."
            value={searchTerm}
            onChange={handleInputChange}
            className="p-3 border border-gray-500 rounded-lg w-full text-gray-300 bg-gray-800"
          />
          <ul className="absolute mt-2 w-full bg-gray-800 text-gray-300 rounded-lg shadow-lg z-10">
            {games.map((game) => (
              <li
                key={game.id}
                className="p-2 hover:bg-gray-700 cursor-pointer flex items-center"
                onClick={() => handleGameClick(game)}
              >
                <img
                  src={game.background_image}
                  alt={game.name}
                  className="w-12 h-12 mr-4 rounded"
                />
                <span>{game.name}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <footer className="text-center text-gray-400 text-sm py-4">
        Powered by{" "}
        <a
          href="https://rawg.io/"
          target="_blank"
          rel="noopener noreferrer"
          className="text-purple-400 hover:underline"
        >
          RAWG
        </a>
      </footer>
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomeSearch />} />
        <Route path="/game/:id" element={<GameDetails />} />
      </Routes>
    </Router>
  );
}

export default App;
